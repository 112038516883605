<template>
  <!--我的订单-->
  <div class="my-enlis" @click.stop="hideSearch">
    <div class="ybox-mod enbuss-mod">
      <div class="ybox-title">
        <card-header :title="headerTitle" :edit="$attrs.edit" @deleteData="deleteData" class="ybox-title">
          <template #left>
            <div class="pull-left">
              <en-icon v-if="flag" name="fanhui" size="small" style="vertical-align:middle;cursor: pointer;margin-left: 0px;" @click.native="callback"> </en-icon>
              <span>我的订单</span>
            </div>
          </template>
          <template #right>
            <div class="pull-right">
              <!-- 搜索下拉框 -->
              <viewSearch v-if="type === 'itself' && !$attrs.edit" :show="show" @toData="getData" :noSearchDate="true"></viewSearch>
              <en-icon v-if="type === 'index'" name="zuocecaidanlan-shouqi" size="20" style="color: #A9B5C6;cursor: pointer;margin-left: 10px;" @click.native="goDetails('/myOrder')"></en-icon>
            </div>
          </template>
        </card-header>

      </div>
      <div class="ybox-content" v-en-loading="loading">
        <div class="enlis enlis-buss" v-if="!noData" id="myorder-enlis">
          <div v-for="(item, k) in noticeList" :key="k" class="mod" @click="modClick(item)">
            <div class="col col-icon">
              <p>
                <span class="iconfont" :style="{ background: icon[item.type].bg }">
                  <en-icon :name="icon[item.type].name" size="small"></en-icon>
                </span>
              </p>
            </div>
            <div class="pull-left">
              <div class="col col-num">
                <p>{{ item["abstract"] || "" }}</p>
              </div>
              <div class="col col-tit">
                <p>￥{{ item["totalAmount"] || 0 }}</p>
              </div>
            </div>
            <div class="pull-right">
              <div class="col col-time">
                <p>{{ getDateFormat(item["modifyTime"]) }}</p>
              </div>
              <div class="col col-operate">
                <el-tag
                  style="height: 24px;line-height: 24px;border:none;border-radius:2px;"
                  :style="'color:' + tripStatus[item.nodeStatus][1] + ';' + 'background:' + tripStatus[item.nodeStatus][2] + ';'"
                >
                  {{ tripStatus[item.nodeStatus][0] }}
                </el-tag>
              </div>
            </div>
          </div>
        </div>
        <en-result v-if="noData" type="NoData"></en-result>
        <!-- 加载更多 -->
        <viewAddMore v-if="myNotice.hasNext" :total="myNotice.total" :pageNo="pageNo" :pageSize="pageSize" @addMore="addMore('/myOrder')"></viewAddMore>
      </div>
    </div>
    <viewBacktop class="back-top" elementId="myorder-enlis"></viewBacktop>

    <nodeDetail v-if="nodeDetailShow" :dialog-visible.sync="nodeDetailShow" :all-node-list="[currentNode]" :current-node="currentNode"> </nodeDetail>
  </div>
</template>

<script>
import { enService } from "@/api/en/index";
import en from "@/mixins/en/en"; // 公共方法
import CardHeader from "@/views/home/components/card-header";
import viewSearch from "./readComm/viewSearch";
import viewBacktop from "./readComm/viewBacktop";
import viewAddMore from "./readComm/viewAddMore";
import nodeDetail from "../../../businessTravel/trip/nodeDetail";

export default {
  name: "MyOrder",
  components: {
    viewSearch,
    CardHeader,
    viewBacktop,
    viewAddMore,
    nodeDetail
  },
  mixins: [en],
  data() {
    return {
      myNotice: {},
      noticeList: [], // 展示的 数据
      pageNo: 1, // 页码
      pageSize: 8, // 每页的 数量
      tripStatus: {
        "000": ["未提交", "rgba(70, 148, 223, 1)", "rgba(70,148,223,0.1)"],
        "001": ["订失败", "rgba(247, 107, 107, 1)", "rgba(247, 107, 107, 0.1)"],
        "002": ["预订中", "rgba(255, 173, 44, 1)", "rgba(255, 173, 44, 0.1)"],
        "003": ["已预订", "rgba(38, 195, 147, 1)", "rgba(38, 195, 147, 0.1)"],
        "004": ["已使用", "rgba(99, 108, 120, 1)", "rgba(99, 108, 120, 0.1)"],
        "005": ["已提交", "rgba(38, 195, 147, 1)", "rgba(38, 195, 147, 0.1)"],
        "006": ["已取消", "rgba(99, 108, 120, 1)", "rgba(99, 108, 120, 0.1)"],
        "007": ["退票中", "rgba(255, 173, 44, 1)", "rgba(255, 173, 44, 0.1)"],
        "008": ["已退票", "rgba(99, 108, 120, 1)", "rgba(99, 108, 120, 0.1)"],
        "009": ["改签中", "rgba(255, 173, 44, 1)", "rgba(255, 173, 44, 0.1)"],
        "010": ["已改签", "rgba(38, 195, 147, 1)", "rgba(38, 195, 147, 0.1)"],
        "011": ["已出票", "rgba(38, 195, 147, 1)", "rgba(38, 195, 147, 0.1)"],
        "012": ["出票中", "rgba(255, 173, 44, 1)", "rgba(255, 173, 44, 0.1)"],
        "013": ["已审批", "rgba(38, 195, 147, 1)", "rgba(38, 195, 147, 0.1)"],
        "014": ["未使用", "rgba(70, 148, 223, 1)", "rgba(70,148,223,0.1)"],
        "015": ["使用中", "rgba(255, 173, 44, 1)", "rgba(255, 173, 44, 0.1)"],
        "017": ["已退款", "rgba(99, 108, 120, 1)", "rgba(99, 108, 120, 0.1)"]
      },
      // 图标颜色
      icon: {
        "000": { name: "jipiaoyuding-shixin", bg: "#49BEF2" }, // 机票
        "001": { name: "jiudianyuding-shixin", bg: "#26C393" }, // 酒店
        "003": { name: "yongcheyuding-shixin", bg: "#FFAD2C" } // 用车
      },
      // 是否显示无数据
      noData: false,
      // 查询条件 加载更多时用到
      params: {},
      show: true,
      currentNode: {},
      nodeDetailShow: false
    };
  },
  mounted() {
    this.handle();
    this.getList();
  },
  computed: {
    headerTitle() {
      return "我的订单";
    }
  },
  methods: {
    deleteData() {
      this.$emit("deleteData");
    },
    // 查询 我的订单数据 params搜索数据
    async getList(flg) {
      if (flg) {
        // flg为true为加载更多不loading
      } else {
        this.loading = true;
      }
      this.loading = true;
      let p = {
        pageSize: this.pageSize,
        pageNo: this.pageNo,
        status: "001"
      };
      if (JSON.stringify(this.params) !== "{}") {
        p = Object.assign(p, this.params);
      }
      if (this.pageNo === 1) {
        this.noticeList = [];
      }
      let rspData;
      try {
        rspData = await enService.queryMyOrderList(p);
      } catch (error) {
        rspData = { records: [] };
      }
      this.myNotice = rspData;
      this.noticeList.push(...rspData.records);
      // 判断是否无数据 避免视图闪现无数据
      if (this.noticeList.length === 0) {
        this.noData = true;
      } else {
        this.noData = false;
      }
      this.loading = false;
    },
    modClick(item) {
      if (item) {
        const obj = {
          id: item.refId,
          tripNodeType: item.type
        };
        this.currentNode = obj;
        this.nodeDetailShow = true;
      }
      // console.log("输出点击项", item);
      // this.$message({
      //   showClose: true,
      //   message: "跳到订单详情",
      //   type: "warning"
      // });
    },
    // 获取搜索数据
    getData(data) {
      // 混入方法
      this.search(data);
    }
  }
};
</script>

<style lang="scss" scoped>
// 最外层
.my-enlis {
  position: relative;
  width: 100%;
  height: 100%;
  .ybox-mod {
    background: #fff;
    margin-bottom: 10px;
    border-radius: 5px;
    height: 100%;
    .card-define-head {
      padding: 5px 20px;
    }
    // 标题
    .ybox-title {
      .pull-left {
        float: left !important;
        font-size: 14px;
        line-height: 40px;
        // font-family: "PingFangSC-Light", "Hiragino Sans GB", Microsoft YaHei Light, Microsoft YaHei, Arial, sans-serif;
        font-weight: bold;
      }

      .pull-right {
        float: right !important;
        height: 40px;
        display: flex;
        align-items: center;
      }
    }

    // 内容
    .ybox-content {
      padding: 0;
      zoom: 1;
      height: calc(100% - 52px);

      .enlis {
        height: calc(100% - 46px);
        overflow-y: auto;
        font-size: 12px;
        padding-bottom: 0;
        padding-left: 10px;
        padding-right: 10px;
        zoom: 1;
        scroll-behavior: smooth; //平滑滚动
        //单个设置
        .mod:last-child {
          border-bottom: 0;
        }

        .mod {
          padding: 14px 2% 0;
          padding-left: 70px;
          line-height: 24px;
          color: #606060;
          cursor: pointer;
          zoom: 1;
          position: relative;
          // min-height: 72px;
          min-height: 80px;
          // border-bottom: 1px solid #f3f3f3;
          background: rgba(232, 236, 242, 0.25);
          border-radius: 4px;
          margin-bottom: 8px;

          .col {
            min-height: 24px;

            p {
              display: inline-block;
              margin: 0;
            }
          }

          // 图标
          .col-icon {
            min-height: 24px;
            position: absolute;
            left: 20px;
            top: 15px;
            width: 40px;

            .iconfont {
              background-color: #3e90fe;
              width: 40px;
              height: 40px;
              color: #fff;
              border-radius: 50%;
              line-height: 40px;
              text-align: center;
              display: block;
              margin: 0;
              font-size: 22px;
              font-style: normal;
            }
          }

          // 左侧
          .pull-left {
            float: left !important;
            text-align: left;
            width: 70%;
            .col-num {
              display: inline-block;
              vertical-align: top;
            }

            .col-tit {
            }
          }

          // 右侧
          .pull-right {
            float: right !important;
            text-align: right;
            width: 20%;
            .col-time {
              color: #91a1b7;
            }
          }
        }
        .mod:after {
          content: "";
          display: block;
          clear: both;
        }
        .mod:hover {
          background: rgba(162, 205, 247, 0.25);
          cursor: pointer;
        }
      }
    }
  }
}
</style>
